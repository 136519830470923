function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function setCookie(name, value) {
    document.cookie = `${name}=${value}; path=/`;
}

function getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function sortTable(section, columnIndex, order) {
    const tableBody = document.querySelector(`#${section}-content .breakdown-table__table tbody`);
    const rows = Array.from(tableBody.querySelectorAll('tr')).filter(row => !row.classList.contains('breakdown-table__row--total'));

    rows.sort(function(a, b) {
        const cellA = a.children[columnIndex].textContent.replace(/,/g, '').toLowerCase();
        const cellB = b.children[columnIndex].textContent.replace(/,/g, '').toLowerCase();
        const valA = isNaN(cellA) ? cellA : parseFloat(cellA);
        const valB = isNaN(cellB) ? cellB : parseFloat(cellB);

        if (valA < valB) {
            return order === 'asc' ? -1 : 1;
        }
        if (valA > valB) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    // Append sorted rows back to the table body
    rows.forEach(row => {
        tableBody.appendChild(row);
    });

    // Re-append the total row at the end
    const totalRow = tableBody.querySelector('.breakdown-table__row--total');
    if (totalRow) {
        tableBody.appendChild(totalRow);
    }
}


let sortState = {};

function restoreSortState(section) {
    if (sortState[section]) {
        const { column, order } = sortState[section];
        const $th = document.querySelectorAll(`#${section}-content .breakdown-table__header th`)[column];
        $th.dataset.order = order;
        sortTable(section, column, order);
    }
}

export default formatNumber;
export { setCookie, getCookie, sortTable, restoreSortState };